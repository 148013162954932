.content-container {
    font-family: "Noto Sans KR", Arial, sans-serif;
    line-height: 1.8;
    padding: 20px;
    background-color: #2b2b2b;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 900px;
}

.content-container h1 {
    font-size: 28px;
    border-bottom: 2px solid #ddd;
    margin-bottom: 20px;
}

.content-container h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.content-container table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.content-container th,
.content-container td {
    border: 1px solid #ddd;
    padding: 10px;
}

.content-container th {
    background-color: #2b2b2b;
}

.footer {
    text-align: center;
    padding: 10px 0;
    background-color: #222;
    color: white;
    margin-top: 20px;
}

.content-container a {
    text-decoration: none;
}

.content-container a:hover {
    text-decoration: underline;
}

.markdown-link {
    color: #79c1ff;
    text-decoration: none;
    border-bottom: none;
    position: relative;
}

.markdown-link:hover {
    text-decoration: underline;
} 

.content-container li {
    text-align: left;
    list-style-position: inside;
    padding-left: 0;
    margin-left: 0;
}

/* 각주 섹션 스타일 */
.footnotes {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 2px solid #444;
    font-size: 0.9em;
    color: #fff;
}

.footnotes ol {
    padding-left: 1em;
}

.footnotes li {
    margin-bottom: 0.8em;
    line-height: 1.6;
}

.footnotes li p {
    display: inline;
    margin: 0;
}

.footnote-backref {
    margin-left: 0.5em;
    color: #79c1ff;
    text-decoration: none;
    font-size: 0.8em;
}

.footnote-backref:hover {
    text-decoration: underline;
}

/* 각주 참조 번호 스타일 */
.footnote-ref {
    position: relative;
    display: inline-block;
}

.footnote-ref a {
    position: relative;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

/* 각주 툴팁 스타일 */
.footnote-tooltip {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    white-space: normal;
    z-index: 1000;
    min-width: 200px;
    max-width: 300px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border: 1px solid #444;
    pointer-events: none;
}

.footnote-tooltip::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
}