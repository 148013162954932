*{
  text-align: center;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'WavvePADO-Regular', sans-serif;
  background-color: #1e2125;
  color: #fff;
}

.App {
  text-align: center;
}

.error{
  padding: 100px;
}

a {
  color: #61dafb;
  text-decoration: none;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@font-face {
  font-family: 'WavvePADO-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/WavvePADO-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mount1{ animation: 0.6s ease-in-out loadEffect1; }

@keyframes loadEffect1 {
    0%{
        opacity: 0;
        transform: translateX(30px);
    }
    50%{
        opacity: 0.5;
        transform: translateX(-30px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

.mount2{ animation: 0.6s ease-in-out loadEffect2; }

@keyframes loadEffect2 {
    0%{
        opacity: 0;
        transform: translateX(-30px);
    }
    50%{
        opacity: 0.5;
        transform: translateX(30px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

.typing-animation {
  overflow: hidden; /* 텍스트가 넘치지 않도록 설정 */
  border-right: 2px solid transparent; /* 타이핑 효과를 줄 border 설정 */
  white-space: nowrap; /* 텍스트가 한 줄에 표시되도록 설정 */
  margin: 0 auto; /* 가운데 정렬 */
  font-size: 20px; /* 원하는 폰트 사이즈로 설정 */
  animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite; /* 타이핑 애니메이션 설정 */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: transparent; /* 깜박임 색상 설정 */
  }
}

.main {
  text-align: center;
  font-size: 15vmin;
}

.contents {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  font-size: 10vmin;
  overflow: hidden;
}

.animate-up {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.in-view {
  opacity: 1;
  transform: translateY(0);
}

.sns-group {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.sns-section sns-button {
  margin: 10px;
  opacity: 0;
  animation-fill-mode: forwards;
}

.sns-button.animate {
  animation: slideUp 1s ease forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.sns-button a {
  position: relative;
  width: 100px;
  height: 100px;
  display: block;
  border: 2px solid white;
  text-align: center;
  margin: 10px;
  border-radius: 10px;
  padding-top: 25px;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: inset 0 0 0 0 white;
  transition: ease-out 0.5s;
}

.sns-group li{
  margin : 0 0.5rem;
}

li {
  display: list-item;
  unicode-bidi: isolate;
}

ul li a .fab {
  display: block;
  border-radius: 3px;
  line-height: calc(60px - 13px);
  font-size: 50px;
  color: white;
  transition: .5s;
}

ul li a .fa-solid {
  display: block;
  border-radius: 3px;
  line-height: calc(60px - 13px);
  font-size: 50px;
  color: white;
  transition: .5s;
}

/* 마우스 오버 효과 */
.sns-button a:hover {
  background-color: white; /* 박스 배경 색상 변경 */
  box-shadow: inset 0 0 0 30px white; /* 박스 내부에 색상을 채우는 효과 */
}

.sns-button a:hover .fab {
  color: #333; /* 아이콘 색상 변경 */
}

.sns-button a:hover .fa-solid {
  color: #333; /* 아이콘 색상 변경 */
}

.tooltip {
  position: absolute;
  bottom: -30px; /* 아이콘 아래쪽에 툴팁을 위치시키기 위한 값 */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sns-button:hover .tooltip {
  opacity: 1;
}

.tooltip .tooltiptext {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  width: 120px;
}

.tooltip-bottom {
  top: 100%; /* 툴팁이 아래쪽에 위치하도록 설정 */
  transform: translateX(-50%);
}

/* 추가된 코드 */
.tooltiptext {
  white-space: nowrap; /* 텍스트가 줄바꿈 없이 한 줄로 표시되도록 설정 */
}

.introduce {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border: #fff 1px solid;
  color: #fff;
  background-color: #2b2b2b;
  font-weight: 50;
  border-radius: 2cap;
  font-size: 20px;
}

@media screen and (max-width:767px) {
  /* 모바일 */
  .introduce {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    border: #fff 1px solid;
    color: #fff;
    background-color: #2b2b2b;
    font-weight: 50;
    border-radius: 2cap;
  }

  .introduce h1 {
    font-size: 20px;
  }

  .introduce p {
    font-size: 10px;
  }

  .mobile-size {
    padding-left: 300px;
    padding-right: 300px;
  }

  .mobile-text h5{
    font-size: 15px;
  }

  .mobile-text p{
    font-size: 10px;
  }

  .sns-button a {
    position: relative;
    width: 50px;
    height: 50px;
    display: block;
    border: 2px solid white;
    text-align: center;
    margin: 0px;
    border-radius: 10px;
    padding-top: 0px;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: inset 0 0 0 0 white;
    transition: ease-out 0.5s;
  }

  ul li a .fab {
    display: block;
    border-radius: 3px;
    line-height: calc(60px - 13px);
    font-size: 20px;
    color: white;
    transition: .5s;
  }
  
  ul li a .fa-solid {
    display: block;
    border-radius: 3px;
    line-height: calc(60px - 13px);
    font-size: 20px;
    color: white;
    transition: .5s;
  }

  .mobile-title{
    padding: 20px;
  }

  .row {
    display: flex;
    justify-content: center;
    margin-left: 50px;
  }
}


.bottom {
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 30px;
  color: #fff;
  background-color: #2b2b2b;
  font-weight: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.projectpage {
  width: 100%;
}

.row {
  display: flex;
  justify-content: center;
}

.footer {
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  color: #fff;
  background-color: #2b2b2b;
  font-weight: 100;
}